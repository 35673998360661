import React from 'react';
import { Auth } from 'aws-amplify';
import GoogleButton from '../assets/googleBtn.png';

const SignIn = () => {
  return (
    <button
      className='googleSignIn'
      onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
    >
      <img src={GoogleButton} alt='Sign in with google' />
    </button>
  );
};

export default SignIn;
