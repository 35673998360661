import React from 'react';
import ColumnInfo from './ColumnInfo';
import CustomerInfo from './CustomerInfo';

const Explanations = () => {
  return (
    <div
      className='colorExplanations'
      style={{ display: 'flex', width: '90%', margin: 'auto' }}
    >
      <CustomerInfo />
      <ColumnInfo />
    </div>
  );
};

export default Explanations;
