import React, { useState } from 'react';
import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Collapse,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const UnhandledCases = ({
  returnedSales,
  returnedProjects,
  mainKeywords,
  returnedArray,
}) => {
  const [salesOpen, setSalesOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const [customersOpen, setCustomersOpen] = useState(false);

  const handleSalesClick = () => {
    setSalesOpen(!salesOpen);
  };

  const handleProjectsClick = () => {
    setProjectsOpen(!projectsOpen);
  };

  const handleCustomersClick = () => {
    setCustomersOpen(!customersOpen);
  };

  const filteredArray = returnedArray.filter((customer) => {
    const matchingKeyword = customer.keywords.find((k) => {
      return mainKeywords.find((m) => m.guid === k.guid);
    });
    return matchingKeyword;
  });

  filteredArray.sort((a, b) => {
    if (a.value < b.value) {
      return 1;
    } else if (a.value > b.value) {
      return -1;
    } else if (a.value === undefined) {
      return 1;
    } else if (b.value === undefined) {
      return -1;
    } else {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  const collectAllSalesCases = [];
  const collectAllProjectCases = [];

  returnedSales.map((customer) => {
    return collectAllSalesCases.push(...customer.salesCases);
  });

  returnedProjects.map((customer) => {
    return collectAllProjectCases.push(...customer.projectCases);
  });

  const salesWithNoMainKeyword = collectAllSalesCases.filter(
    (saleCase) =>
      !saleCase.keywords.find((k) => {
        return mainKeywords.find((m) => m.guid === k.guid);
      })
  );

  const projectsWithNoMainKeyword = collectAllProjectCases.filter(
    (projectCase) =>
      !projectCase.keywords.find((k) => {
        return mainKeywords.find((m) => m.guid === k.guid);
      })
  );

  const customersNotClassified = filteredArray.filter((customer) => {
    return customer.accountType === undefined;
  });
  return (
    <div className='unhandledCases'>
      <div>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component='nav'
        >
          <ListItemButton onClick={handleSalesClick}>
            <ListItemText primary='Untagged sales cases' />
            {salesOpen ? <ExpandLess /> : <ExpandMore />}
            {salesWithNoMainKeyword.length > 0 ? (
              <b style={{ color: 'red' }}>
                {salesWithNoMainKeyword.length} kpl
              </b>
            ) : (
              <b style={{ color: 'black' }}>
                {salesWithNoMainKeyword.length} kpl
              </b>
            )}
          </ListItemButton>
          <Collapse in={salesOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {salesWithNoMainKeyword.map((sales) => {
                return (
                  <ListItemButton
                    key={sales.guid}
                    sx={{ pl: 4 }}
                    component={'a'}
                    href={`https://severa.visma.com/project/${sales.guid}/settings`}
                    target={'_blank'}
                  >
                    <ListItemText>{sales.salesName}</ListItemText>
                    <ListSubheader>{sales.customerName}</ListSubheader>
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </List>
      </div>
      <div>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component='nav'
        >
          <ListItemButton onClick={handleProjectsClick}>
            <ListItemText primary='Untagged project cases' />
            {projectsOpen ? <ExpandLess /> : <ExpandMore />}
            {projectsWithNoMainKeyword.length > 0 ? (
              <b style={{ color: 'red' }}>
                {projectsWithNoMainKeyword.length} kpl
              </b>
            ) : (
              <b style={{ color: 'black' }}>
                {projectsWithNoMainKeyword.length} kpl
              </b>
            )}
          </ListItemButton>
          <Collapse in={projectsOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {projectsWithNoMainKeyword.map((project) => {
                return (
                  <ListItemButton
                    key={project.guid}
                    sx={{ pl: 4 }}
                    component={'a'}
                    href={`https://severa.visma.com/project/${project.guid}/settings`}
                    target={'_blank'}
                  >
                    <ListItemText>{project.projectName}</ListItemText>
                    <ListSubheader>{project.name}</ListSubheader>
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </List>
      </div>
      <div>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component='nav'
        >
          <ListItemButton onClick={handleCustomersClick}>
            <ListItemText primary='Not classified Customers' />
            {customersOpen ? <ExpandLess /> : <ExpandMore />}
            {customersNotClassified.length > 0 ? (
              <b style={{ color: 'red' }}>
                {customersNotClassified.length} kpl
              </b>
            ) : (
              <b style={{ color: 'black' }}>
                {customersNotClassified.length} kpl
              </b>
            )}
          </ListItemButton>
          <Collapse in={customersOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {customersNotClassified.map((customer) => {
                return (
                  <ListItemButton
                    key={customer.guid}
                    sx={{ pl: 4 }}
                    component={'a'}
                    href={`https://severa.visma.com/customer/${customer.guid}/settings`}
                    target={'blank'}
                  >
                    <ListItemText>{customer.name}</ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </List>
      </div>
    </div>
  );
};

export default UnhandledCases;
