import React from 'react';
import { Euro, Dvr, Square, ExpandLess, ExpandMore } from '@mui/icons-material';

const ColumnInfo = () => {
  return (
    <div className='column-explanation'>
      <div className='salesColumnExplanation'>
        <div className='salesColumnHeader'>{<Euro />} Sales column</div>
        <div>
          {<Square style={{ color: '#8bbd78' }} fontSize='medium' />} Closing
        </div>
        <div>
          {<Square style={{ color: 'rgb(247, 189, 83)' }} fontSize='medium' />}{' '}
          Building
        </div>
        <div>
          {<Square style={{ color: 'lightskyblue' }} fontSize='medium' />}{' '}
          Qualifying
        </div>
      </div>
      <div>
        <div className='projectColumnHeader'>{<Dvr />} Project column</div>
        <div>
          {<Square style={{ color: 'lightgreen' }} fontSize='medium' />} Ongoing
          project
        </div>
      </div>
    </div>
  );
};

export default ColumnInfo;
