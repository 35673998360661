import React from 'react';
import { TableHead, TableCell, TableRow } from '@mui/material';
import { Euro, Dvr } from '@mui/icons-material';

const HorizontalTableHead = ({ filteredArray, showImportantCustomers }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            position: 'sticky',
            left: 0,
            backgroundColor: 'white',
            color: 'white',
            backgroundColor: 'lightgray',
          }}
        >
          <div className='headerRow-emptyCell'>&nbsp;</div>
        </TableCell>
        {filteredArray.map((customer) => {
          if (showImportantCustomers) {
            if (customer.value === 4) {
              return (
                <TableCell
                  style={{ backgroundColor: '#C6A2E8' }}
                  key={customer.guid}
                >
                  <div className='customer-header-div'>{customer.name}</div>
                </TableCell>
              );
            } else {
              return;
            }
          }

          if (customer.value === 4) {
            return (
              <TableCell
                key={customer.guid}
                style={{ backgroundColor: '#C6A2E8' }}
              >
                <div className='customer-header-div'>{customer.name}</div>
              </TableCell>
            );
          } else if (customer.value === 3 && !showImportantCustomers) {
            return (
              <TableCell
                key={customer.guid}
                style={{ backgroundColor: 'lightsteelblue' }}
              >
                <div className='customer-header-div'>{customer.name}</div>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={customer.guid}
                style={{ backgroundColor: 'lightcyan' }}
              >
                <div className='customer-header-div'>{customer.name}</div>
              </TableCell>
            );
          }
        })}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            position: 'sticky',
            left: 0,
            backgroundColor: 'white',
            color: 'white',
            backgroundColor: 'lightgray',
          }}
        >
          &nbsp;
        </TableCell>
        {filteredArray.map((customer) => {
          if (
            customer.value === 4 ||
            (customer.value !== 4 && !showImportantCustomers)
          )
            return (
              <TableCell key={customer.guid}>
                <div
                  className='horizontalTableIconsRow'
                  style={{ display: 'flex' }}
                >
                  <div>{<Euro />}</div>
                  <div>{<Dvr />}</div>
                </div>
              </TableCell>
            );
        })}
      </TableRow>
    </TableHead>
  );
};

export default HorizontalTableHead;
