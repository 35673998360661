import React from 'react';
import { TableHead, TableCell, TableRow } from '@mui/material';
import { Euro, Dvr } from '@mui/icons-material';

const VerticalTableHead = ({ mainKeywords }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '25%' }}>
          <b>Customer</b>
        </TableCell>
        {mainKeywords.length === 0 ? (
          <TableCell>Loading...</TableCell>
        ) : (
          mainKeywords.map((keyword) => {
            return (
              <TableCell key={keyword.guid} style={{ width: '19%' }}>
                <div style={{ textAlign: 'center' }}>
                  <b>{keyword.name}</b>
                </div>
              </TableCell>
            );
          })
        )}
      </TableRow>
      <TableRow>
        <TableCell>&nbsp;</TableCell>
        {mainKeywords.length === 0 ? (
          <TableCell>Loading...</TableCell>
        ) : (
          mainKeywords.map((keyword) => {
            return (
              <TableCell key={keyword.guid}>
                <div style={{ display: 'flex' }}>
                  <div
                    className='icon'
                    style={{ textAlign: 'center', width: '50%' }}
                  >
                    {<Euro />}
                  </div>
                  <div
                    className='icon'
                    style={{ textAlign: 'center', width: '50%' }}
                  >
                    {<Dvr />}
                  </div>
                </div>
              </TableCell>
            );
          })
        )}
      </TableRow>
    </TableHead>
  );
};

export default VerticalTableHead;
