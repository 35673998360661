import React from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HorizontalTableBody = ({
  returnedCustomerSegments,
  returnedArray,
  mainKeywords,
  filteredArray,
  showImportantCustomers,
  returnedSales,
  returnedProjects,
}) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const getSales = (customer, keywordGuid) => {
    let saleNumber;
    let saleCaseName;
    let salesCases = [];
    const saleResult = returnedSales.find(
      (sale) => sale.guid === customer.guid
    );

    if (!saleResult) {
      saleNumber = 0;
    } else {
      saleResult.salesCases.filter((saleCase) => {
        if (saleCase.keywords.find((keyword) => keyword.guid === keywordGuid)) {
          saleNumber = 1;
          salesCases.push(saleCase);
        }
      });
    }

    if (saleNumber === 1) {
      salesCases.find((saleCase) => {
        if (saleCase.salesStatus.name === 'Closing') {
          saleNumber = 3;
        } else if (saleCase.salesStatus.name === 'Building') {
          saleNumber = 2;
        } else if (saleCase.salesStatus.name === 'Qualifying') {
          saleNumber = 1;
        }
      });
    }

    if (saleNumber === 3) {
      return (
        <BootstrapTooltip
          title={salesCases.map((sale) => {
            return (
              <React.Fragment key={sale.guid}>
                <Typography>- {sale.salesName}</Typography>
              </React.Fragment>
            );
          })}
        >
          <div className='keywordClosing borderRight'>&nbsp;</div>
        </BootstrapTooltip>
      );
    } else if (saleNumber === 2) {
      return (
        <BootstrapTooltip
          title={salesCases.map((sale) => {
            return (
              <React.Fragment key={sale.guid}>
                <Typography>- {sale.salesName}</Typography>
              </React.Fragment>
            );
          })}
        >
          <div className='keywordBuilding borderRight'>&nbsp;</div>
        </BootstrapTooltip>
      );
    } else if (saleNumber === 1) {
      return (
        <BootstrapTooltip
          title={salesCases.map((sale) => {
            return (
              <React.Fragment key={sale.guid}>
                <Typography>- {sale.salesName}</Typography>
              </React.Fragment>
            );
          })}
        >
          <div className='keywordQualifying borderRight'>&nbsp;</div>
        </BootstrapTooltip>
      );
    } else {
      return <div className='keywordNotFound borderRight'>&nbsp;</div>;
    }
  };

  const getProjects = (customer, keywordGuid) => {
    let projectNumber;
    let projectCases = [];
    const projectResult = returnedProjects.find(
      (project) => project.name === customer.name
    );
    if (!projectResult) {
      projectNumber = 0;
    } else {
      projectResult.projectCases.filter((projectCase) => {
        if (
          projectCase.keywords.find((keyword) => keyword.guid === keywordGuid)
        ) {
          projectNumber = 1;
          projectCases.push(projectCase);
        }
      });
    }
    if (projectNumber === 1) {
      return (
        <BootstrapTooltip
          title={projectCases.map((project) => {
            return (
              <React.Fragment key={project.guid}>
                <Typography>- {project.projectName}</Typography>
              </React.Fragment>
            );
          })}
        >
          <div className='keywordFound'>&nbsp;</div>
        </BootstrapTooltip>
      );
    } else {
      return <div className='keywordNotFound'>&nbsp;</div>;
    }
  };

  return (
    <TableBody>
      {mainKeywords.map((keyword) => {
        return (
          <TableRow key={keyword.guid}>
            <TableCell
              padding={'none'}
              style={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'lightgray',
                height: '30px',
              }}
            >
              <b>{keyword.name}</b>
            </TableCell>
            {filteredArray.map((customer) => {
              if (
                customer.value === 4 ||
                (customer.value !== 4 && !showImportantCustomers)
              )
                return (
                  <TableCell
                    padding={'none'}
                    key={customer.guid}
                    style={{
                      borderRight: '1px solid black',
                      borderTop: '1px solid lightgray',
                    }}
                  >
                    <div style={{ display: 'flex', height: '30px' }}>
                      {getSales(customer, keyword.guid)}
                      {getProjects(customer, keyword.guid)}
                    </div>
                  </TableCell>
                );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default HorizontalTableBody;
