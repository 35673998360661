import React, { useState, useEffect } from 'react';
import Amplify, { API, Auth, Hub } from 'aws-amplify';
// import { Authenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import VerticalTable from './components/VerticalTable';
import HorizontalTable from './components/HorizontalTable/HorizontalTable';
import Info from './components/Info';
import UnhandledCases from './components/UnhandledCases';
import './App.css';
import SignIn from './components/SignIn';
import Groups from './config.js';

const isLocalhost = Boolean(window.location.hostname === 'localhost');

const [localRedirectSignIn, productionRedirectSignIn] =
  awsExports.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut] =
  awsExports.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

function App() {
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const [sales, setSales] = useState([]);
  const [projects, setProjects] = useState([]);
  const [mainKeywords, setMainKeywords] = useState([]);
  const [customerSegments, setCustomerSegments] = useState([]);
  const [closedProjects, setClosedProjects] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showImportantCustomers, setShowImportantCustomers] = useState(false);
  const [showVerticalTable, setShowVerticalTable] = useState(true);

  async function getUser() {
    try {
      const token = await Auth.currentAuthenticatedUser();
      setUser(token);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signin') {
        return getUser();
      }

      if (payload.event === 'signout') {
        setUser(null);
      }
    });

    getUser();
  }, []);

  useEffect(() => {
    API.post('whitespot2', '/token').then((response) => {
      setData(response);
    });
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    setSales(data.sales);
    setProjects(data.projects);
    setCustomerSegments(data.customerSegments);
    setMainKeywords(data.mainKeywords);
    setTableData([...data.sales, ...data.projects]);
    setClosedProjects(data.closedProjects);
  }, [data]);

  tableData.map((customerObject) => {
    if (
      customerObject.customer.guid === Groups.groups[0].members[0] ||
      customerObject.customer.guid === Groups.groups[0].members[1]
    ) {
      customerObject.customer.name = Groups.groups[0].groupAs;
    } else if (
      customerObject.customer.guid === Groups.groups[1].members[0] ||
      customerObject.customer.guid === Groups.groups[1].members[1] ||
      customerObject.customer.guid === Groups.groups[1].members[2] ||
      customerObject.customer.guid === Groups.groups[1].members[3]
    ) {
      customerObject.customer.name = Groups.groups[1].groupAs;
    } else if (
      customerObject.customer.guid === Groups.groups[2].members[0] ||
      customerObject.customer.guid === Groups.groups[2].members[1] ||
      customerObject.customer.guid === Groups.groups[2].members[2] ||
      customerObject.customer.guid === Groups.groups[2].members[3]
    ) {
      customerObject.customer.name = Groups.groups[2].groupAs;
    }
  });

  sales.map((customerObject) => {
    if (
      customerObject.customer.guid === Groups.groups[0].members[0] ||
      customerObject.customer.guid === Groups.groups[0].members[1]
    ) {
      customerObject.customer.name = Groups.groups[0].groupAs;
    } else if (
      customerObject.customer.guid === Groups.groups[1].members[0] ||
      customerObject.customer.guid === Groups.groups[1].members[1] ||
      customerObject.customer.guid === Groups.groups[1].members[2] ||
      customerObject.customer.guid === Groups.groups[1].members[3]
    ) {
      customerObject.customer.name = Groups.groups[1].groupAs;
    } else if (
      customerObject.customer.guid === Groups.groups[2].members[0] ||
      customerObject.customer.guid === Groups.groups[2].members[1] ||
      customerObject.customer.guid === Groups.groups[2].members[2] ||
      customerObject.customer.guid === Groups.groups[2].members[3]
    ) {
      customerObject.customer.name = Groups.groups[2].groupAs;
    }
  });

  projects.map((customerObject) => {
    if (
      customerObject.customer.guid === Groups.groups[0].members[0] ||
      customerObject.customer.guid === Groups.groups[0].members[1]
    ) {
      customerObject.customer.name = Groups.groups[0].groupAs;
    } else if (
      customerObject.customer.guid === Groups.groups[1].members[0] ||
      customerObject.customer.guid === Groups.groups[1].members[1] ||
      customerObject.customer.guid === Groups.groups[1].members[2] ||
      customerObject.customer.guid === Groups.groups[1].members[3]
    ) {
      customerObject.customer.name = Groups.groups[1].groupAs;
    } else if (
      customerObject.customer.guid === Groups.groups[2].members[0] ||
      customerObject.customer.guid === Groups.groups[2].members[1] ||
      customerObject.customer.guid === Groups.groups[2].members[2] ||
      customerObject.customer.guid === Groups.groups[2].members[3]
    ) {
      customerObject.customer.name = Groups.groups[2].groupAs;
    }
  });

  const returnedArray = tableData.reduce((accumulator, customerObject) => {
    const foundObject = accumulator.find(
      (object) =>
        object.guid === customerObject.customer.guid ||
        object.name === customerObject.customer.name
    );
    if (!foundObject) {
      if (!customerObject.keywords) {
        accumulator.push({
          guid: customerObject.customer.guid,
          name: customerObject.customer.name,
          keywords: [],
        });
      } else {
        accumulator.push({
          guid: customerObject.customer.guid,
          name: customerObject.customer.name,
          keywords: [...customerObject.keywords],
        });
      }
    } else {
      if (customerObject.keywords) {
        foundObject.keywords = [
          ...foundObject.keywords,
          ...customerObject.keywords,
        ];
      }
    }
    return accumulator;
  }, []);

  const returnedSales = sales.reduce((accumulator, salesObject) => {
    const foundObject = accumulator.find(
      (object) => object.guid === salesObject.customer.guid
    );
    if (!foundObject) {
      accumulator.push({
        name: salesObject.customer.name,
        guid: salesObject.customer.guid,
        salesCases: [
          {
            salesName: salesObject.name,
            customerName: salesObject.customer.name,
            guid: salesObject.guid,
            salesStatus: salesObject.salesStatus,
            keywords: salesObject.keywords ? salesObject.keywords : [],
          },
        ],
      });
    } else {
      if (salesObject.keywords) {
        foundObject.salesCases = [
          ...foundObject.salesCases,
          {
            salesName: salesObject.name,
            customerName: salesObject.customer.name,
            guid: salesObject.guid,
            salesStatus: salesObject.salesStatus,
            keywords: salesObject.keywords,
          },
        ];
      }
    }
    return accumulator;
  }, []);

  const returnedProjects = projects.reduce((accumulator, projectObject) => {
    const foundObject = accumulator.find(
      (object) => object.name === projectObject.customer.name
    );
    if (!foundObject) {
      accumulator.push({
        name: projectObject.customer.name,
        guid: projectObject.guid,
        projectCases: [
          {
            projectName: projectObject.name,
            customerName: projectObject.customer.name,
            guid: projectObject.guid,
            keywords: projectObject.keywords ? projectObject.keywords : [],
          },
        ],
      });
    } else {
      if (projectObject.keywords) {
        foundObject.projectCases = [
          ...foundObject.projectCases,
          {
            projectName: projectObject.name,
            customerName: projectObject.customer.name,
            guid: projectObject.guid,
            keywords: projectObject.keywords,
          },
        ];
      }
    }
    return accumulator;
  }, []);

  const returnedCustomerSegments = customerSegments.map((customer) => {
    return {
      guid: customer.customer.guid,
      name: customer.customer.name,
      accountType: customer.marketSegment.name,
    };
  });

  const showOnlyImportantCustomers = () => {
    setShowImportantCustomers(!showImportantCustomers);
  };

  const toggleTableFormat = (event) => {
    setShowVerticalTable(!showVerticalTable);
  };

  return (
    <div className='App'>
      {user ? (
        <div>
          <h2>Sales white spot</h2>
          <button onClick={() => Auth.signOut()}>Sign out</button>
          <div className='checkboxes'>
            <div className='key-account-checkbox-div'>
              <input type='checkbox' onChange={showOnlyImportantCustomers} />
              <label className='keyAccountInputLabel'>
                <b>Show only key accounts</b>
              </label>
            </div>
            <div className='horizontal-table-checkbox-div'>
              <input
                type='checkbox'
                value={showVerticalTable}
                onChange={toggleTableFormat}
              />
              <label>
                <b>Show horizontal table</b>
              </label>
            </div>
          </div>
          {showVerticalTable ? (
            <VerticalTable
              returnedCustomerSegments={returnedCustomerSegments}
              mainKeywords={mainKeywords}
              returnedArray={returnedArray}
              returnedSales={returnedSales}
              returnedProjects={returnedProjects}
              showImportantCustomers={showImportantCustomers}
            />
          ) : (
            <HorizontalTable
              returnedCustomerSegments={returnedCustomerSegments}
              mainKeywords={mainKeywords}
              returnedArray={returnedArray}
              returnedSales={returnedSales}
              returnedProjects={returnedProjects}
              showImportantCustomers={showImportantCustomers}
            />
          )}

          <Info />
          <UnhandledCases
            returnedSales={returnedSales}
            returnedProjects={returnedProjects}
            mainKeywords={mainKeywords}
            returnedArray={returnedArray}
            closedProjects={closedProjects}
          />
        </div>
      ) : (
        <SignIn />
      )}
    </div>
  );
}

export default App;
