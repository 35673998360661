import React from 'react';
import { TableContainer, Table, Paper } from '@mui/material';
import HorizontalTableHead from './HorizontalTableHead';
import HorizontalTableBody from './HorizontalTableBody';

const HorizontalTable = ({
  returnedCustomerSegments,
  returnedArray,
  mainKeywords,
  showImportantCustomers,
  returnedSales,
  returnedProjects,
}) => {
  returnedArray.forEach((customer) => {
    const findCustomerObject = returnedCustomerSegments.find(
      (c) => c.guid === customer.guid
    );
    if (findCustomerObject) {
      customer.accountType = findCustomerObject.accountType;
      if (customer.accountType === 'Key Account') {
        customer.value = 4;
      } else if (customer.accountType === 'Potential Key Account') {
        customer.value = 3;
      } else if (customer.accountType === 'Regular Account') {
        customer.value = 2;
      } else if (customer.accountType === 'Inactive') {
        customer.value = 1;
      }
    }
  });

  const filteredArray = returnedArray.filter((customer) => {
    //console.log(customer);
    // check whether this customer has any sales / projects cases with any of the `mainKeywords`

    const matchingKeyword = customer.keywords.find((k) => {
      return mainKeywords.find((m) => m.guid === k.guid);
    });
    return matchingKeyword;
  });

  filteredArray.sort((a, b) => {
    if (a.value < b.value) {
      return 1;
    } else if (a.value > b.value) {
      return -1;
    } else if (a.value === undefined) {
      return 1;
    } else if (b.value === undefined) {
      return -1;
    } else {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return (
    <TableContainer component={Paper} style={{ width: '90%', margin: 'auto' }}>
      <Table>
        <HorizontalTableHead
          returnedCustomerSegments={returnedCustomerSegments}
          filteredArray={filteredArray}
          showImportantCustomers={showImportantCustomers}
        />
        <HorizontalTableBody
          returnedCustomerSegments={returnedCustomerSegments}
          returnedArray={returnedArray}
          filteredArray={filteredArray}
          mainKeywords={mainKeywords}
          showImportantCustomers={showImportantCustomers}
          returnedSales={returnedSales}
          returnedProjects={returnedProjects}
        />
      </Table>
    </TableContainer>
  );
};

export default HorizontalTable;
