module.exports = {
  groups: [
    {
      members: [
        'ba047869-4267-2f09-0531-c293c7ac3a8c',
        'c1f08a28-1bcd-dddd-5e14-6ee67131a065',
      ],
      groupAs: 'Vincit',
    },
    {
      members: [
        '9c709c52-fda9-8c69-9c18-551e901c30e6',
        '2533e89c-5015-9878-2f84-c5b68bc6a99b',
        '6ae58a7c-cc45-d0da-906d-1a121c6f6b6d',
        'e1c434b7-7b44-8ad2-f4eb-9b446f8e74c4',
      ],
      groupAs: 'Ilmatieteen laitos',
    },
    {
      members: [
        '2a0990e3-01ea-8c8e-0443-0f2745a4c036',
        '458c8619-3f43-9089-d120-b96530035fd2',
        '78084b43-9839-6bb8-033b-e4973ac0471e',
        'e6eb3d3e-9e85-b178-863e-ce897b440867',
      ],
      groupAs: 'Maanmittauslaitos',
    },
  ],
};
