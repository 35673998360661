import React from 'react';
import { TableContainer, Table, Paper } from '@mui/material';
import '../App.css';
import VerticalTableHead from './VerticalTableHead';
import VerticalTableBody from './VerticalTableBody';

const VerticalTable = ({
  returnedCustomerSegments,
  returnedArray,
  mainKeywords,
  showImportantCustomers,
  returnedSales,
  returnedProjects,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 500 }}
      style={{ width: '90%', margin: 'auto' }}
    >
      <Table stickyHeader>
        <VerticalTableHead mainKeywords={mainKeywords} />
        <VerticalTableBody
          returnedCustomerSegments={returnedCustomerSegments}
          returnedArray={returnedArray}
          returnedSales={returnedSales}
          returnedProjects={returnedProjects}
          mainKeywords={mainKeywords}
          showImportantCustomers={showImportantCustomers}
        />
      </Table>
    </TableContainer>
  );
};

export default VerticalTable;
