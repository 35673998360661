import React from 'react';
import { Square } from '@mui/icons-material';

const CustomerInfo = () => {
  return (
    <div className='customerColorExplanation'>
      <div className='customerColumn'>Customer account type</div>
      <div>{<Square style={{ color: '#C6A2E8' }} />} Key account</div>
      <div>
        {<Square style={{ color: 'lightsteelblue' }} />} Potential key account
      </div>
      <div>{<Square style={{ color: 'lightcyan' }} />} Regular account</div>
      <div>{<Square style={{ color: 'antiquewhite' }} />} Not classified</div>
    </div>
  );
};

export default CustomerInfo;
