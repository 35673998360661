import React from 'react';
import { TableBody, TableRow, TableCell } from '@mui/material';
import CustomerRow from './CustomerRow';

const VerticalTableBody = ({
  returnedCustomerSegments,
  returnedArray,
  mainKeywords,
  showImportantCustomers,
  returnedSales,
  returnedProjects,
}) => {
  returnedArray.forEach((customer) => {
    const findCustomerObject = returnedCustomerSegments.find(
      (c) => c.guid === customer.guid
    );
    if (findCustomerObject) {
      customer.accountType = findCustomerObject.accountType;
      if (customer.accountType === 'Key Account') {
        customer.value = 4;
      } else if (customer.accountType === 'Potential Key Account') {
        customer.value = 3;
      } else if (customer.accountType === 'Regular Account') {
        customer.value = 2;
      } else if (customer.accountType === 'Inactive') {
        customer.value = 1;
      }
    }
  });

  const filteredArray = returnedArray.filter((customer) => {
    //console.log(customer);
    // check whether this customer has any sales / projects cases with any of the `mainKeywords`

    const matchingKeyword = customer.keywords.find((k) => {
      return mainKeywords.find((m) => m.guid === k.guid);
    });
    return matchingKeyword;
  });

  filteredArray.sort((a, b) => {
    if (a.value < b.value) {
      return 1;
    } else if (a.value > b.value) {
      return -1;
    } else if (a.value === undefined) {
      return 1;
    } else if (b.value === undefined) {
      return -1;
    } else {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return (
    <TableBody>
      {filteredArray.length === 0 ? (
        <TableRow>
          <TableCell>Loading...</TableCell>
        </TableRow>
      ) : (
        filteredArray.map((customer) => {
          if (showImportantCustomers) {
            if (customer.value === 4) {
              return (
                <CustomerRow
                  key={customer.guid}
                  customer={customer}
                  backgroundColor={'#C6A2E8'}
                  mainKeywords={mainKeywords}
                  returnedSales={returnedSales}
                  returnedProjects={returnedProjects}
                />
              );
            } else {
              return;
            }
          }
          if (customer.value === 4) {
            return (
              <CustomerRow
                key={customer.guid}
                customer={customer}
                backgroundColor={'#C6A2E8'}
                mainKeywords={mainKeywords}
                returnedSales={returnedSales}
                returnedProjects={returnedProjects}
              />
            );
          } else if (customer.value === 3) {
            return (
              <CustomerRow
                key={customer.guid}
                customer={customer}
                backgroundColor={'lightsteelblue'}
                mainKeywords={mainKeywords}
                returnedSales={returnedSales}
                returnedProjects={returnedProjects}
              />
            );
          } else {
            return (
              <CustomerRow
                key={customer.guid}
                customer={customer}
                backgroundColor={'lightcyan'}
                mainKeywords={mainKeywords}
                returnedSales={returnedSales}
                returnedProjects={returnedProjects}
              />
            );
          }
        })
      )}
    </TableBody>
  );
};

export default VerticalTableBody;
